<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn class="mr-3" color="success" @click="abrirDialogoNovaSolicitacao">NOVA AQUISIÇÃO</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table :headers="grid.headers" :items="filteredItems" :loading="grid.loading"
          loading-text="Carregando... aguarde" locale="pt" class="elevation-1">
          <template v-slot:item.codigoMec="{ item }">
            <span>{{ item.codigoMec }}</span>
          </template>

          <template v-slot:item.cnpjUex="{ item }">
            <span>{{ item.cnpjUex }}</span>
          </template>

          <template v-slot:item.status="{ item }">
            <span>{{ item.status }}</span>
          </template>

          <template v-slot:item.dataEnvio="{ item }">
            <span>{{ item.dataEnvio }}</span>
          </template>

          <template v-slot:item.valorRepasse="{ item }">
            <span>{{ item.valorRepasse | toCurrency }}</span>
          </template>

          <template v-slot:item.valorCusteio="{ item }">
            <span>{{ item.valorCusteio | toCurrency }}</span>
          </template>

          <template v-slot:item.valorCapital="{ item }">
            <span>{{ item.valorCapital | toCurrency }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon color="blue" class="mr-3" @click="visualizarSolicitacao(item)">
                mdi-eye-outline
              </v-icon>
              <v-icon color="black" class="mr-3" v-if="verificarAcoesDaSolicitacaoAlterAquisicao(item)"
                @click="visualizarSolicitacaoAlteracaoAquisicao(item)">
                mdi-cog
              </v-icon>
              <v-icon color="green" class="mr-3" v-if="!verificarAcoesDaSolicitacao(item)"
                @click="editarRecursoDeCusteio(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="orange" class="mr-3" v-if="!verificarAcoesDaSolicitacao(item)"
                @click="editarRecursoDeCapital(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="red" class="mr-3" v-if="!verificarAcoesDaSolicitacao(item)"
                @click="deletarSolicitacao(item)">
                mdi-delete
              </v-icon>

              <v-icon color="black" @click="downloadComprovante(item)">
                mdi-download
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp || mobileFilter" v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp" :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown" right class="elevation-1">
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2">Filtros</v-card-title>
            <v-text-field v-model="grid.filters.cnpjUex" label="CNPJ Uex" dense solo></v-text-field>

            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!--Dialogo Visualizar Solicitacao -->

    <v-row justify="center">
      <v-dialog v-model="dialogoVisualizarSolicitacao" persistent max-width="100%">
        <v-card>
          <v-card-title class="d-flex justify-space-between mb-6">
            <span class="text-h5">Dados da solicitação</span>
            <div>
              <v-icon class="mr-3" @click="imprimirPdfSolicitacao('Dados da solicitação')">
                mdi-printer
              </v-icon>
              <v-icon @click="fecharDialogoVisualizarSolicitacao()">
                mdi-arrow-left-thick
              </v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CUSTEIO (Material de Consumo)
                  </h2>
                  <v-data-table :headers="gridDetalharSolicitacao.headers" :items="solicitacao.recursoCusteio.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }" :items-per-page="3"
                    no-data-text="Adicione itens">
                    <template v-slot:item.tipoMaterial="{ item }">
                      <span>{{ item.tipoMaterial.materialNome }}</span>
                    </template>

                    <template v-slot:item.material="{ item }">
                      <span>{{ item.nome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-icon v-if="item.status !== ''" :color="definirCorIconStatusItem(item)" class="mr-3">
                        {{ definirIconStatusItem(item) }}
                      </v-icon>
                      <span v-else>{{ item.status }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CAPITAL (Material Permanente)
                  </h2>
                  <v-data-table :headers="gridDetalharSolicitacao.headers" :items="solicitacao.recursoCapital.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }" :items-per-page="3"
                    no-data-text="Adicione itens">
                    <template v-slot:item.materialNome="{ item }">
                      <span>{{ item.materialNome }}</span>
                    </template>

                    <template v-slot:item.itemNome="{ item }">
                      <span>{{ item.itemNome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>

                    <template v-slot:item.status="{ item }">
                      <v-icon v-if="item.status !== ''" :color="definirCorIconStatusItem(item)" class="mr-3">
                        {{ definirIconStatusItem(item) }}
                      </v-icon>
                      <span v-else>{{ item.status }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoVisualizarSolicitacao()">
              Fechar
            </v-btn>
            <v-btn v-if="exibirBotaoEnviarSolicitacao" :disabled="!validarPercentualMinimoSolicitacao"
              color="blue darken-1" text @click="enviarParaGerenciaRegional()">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Fim Dialog Visualizar Solicitacao -->

    <!--Dialogo Visualizar Solicitacao Alteracao de Aquisicao -->
    <v-row justify="center">
      <v-dialog v-model="dialogoAlteracaoAquisicao" persistent max-width="100%">
        <v-card>
          <v-card-title class="d-flex justify-space-between mb-6">
            <span class="text-h5">Solicitar Alteração de Aquisição</span>
            <div>
              <v-icon class="mr-3" @click="
                imprimirPdfSolicitacao('Solicitar Alteração de Aquisição')
              ">
                mdi-printer
              </v-icon>
              <v-icon @click="fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao()">
                mdi-arrow-left-thick
              </v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CUSTEIO (Material de Consumo)
                  </h2>
                  <v-data-table :headers="gridSolicitacaoAlterarAquisicao.headers"
                    :items="solicitacao.recursoCusteio.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }" :items-per-page="3"
                    no-data-text="Adicione itens">
                    <template v-slot:item.tipoMaterial="{ item }">
                      <span>{{ item.tipoMaterial.materialNome }}</span>
                    </template>

                    <template v-slot:item.material="{ item }">
                      <span>{{ item.nome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-icon color="green" class="mr-3"
                          @click="editarRecusoItemSolicitacaoAlteracaoRequisicao(item, 'custeio')">
                          mdi-pencil
                        </v-icon>
                        <v-icon color="red" @click="deletarRecursoDeCusteio(item)">
                          mdi-delete
                        </v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="text-right pa-0">
                  <v-btn :disabled="!permitirAdicionarRecursoCusteio"
                    @click="mostrarDialogoAdicionarMateriais('custeio')" elevation="2" color="green" fab outlined small>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pa-0">
                  <h2 class="text-center">
                    RECURSOS DE CAPITAL (Material Permanente)
                  </h2>
                  <v-data-table :headers="gridSolicitacaoAlterarAquisicao.headers"
                    :items="solicitacao.recursoCapital.materiais"
                    :footer-props="{ 'items-per-page-options': [3, 5, -1] }" :items-per-page="3"
                    no-data-text="Adicione itens">
                    <template v-slot:item.materialNome="{ item }">
                      <span>{{ item.materialNome }}</span>
                    </template>

                    <template v-slot:item.itemNome="{ item }">
                      <span>{{ item.itemNome }}</span>
                    </template>

                    <template v-slot:item.quantidade="{ item }">
                      <span>{{ item.quantidade }}</span>
                    </template>

                    <template v-slot:item.valorUnitario="{ item }">
                      <span>{{ item.valorUnitario | toCurrency }}</span>
                    </template>

                    <template v-slot:item.valorTotal="{ item }">
                      <span>{{ item.valorTotal | toCurrency }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-icon color="orange" class="mr-3"
                          @click="editarRecusoItemSolicitacaoAlteracaoRequisicao(item, 'capital')">
                          mdi-pencil
                        </v-icon>
                        <v-icon color="red" @click="deletarRecursoCapital(item)">
                          mdi-delete
                        </v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="text-right pa-0">
                  <v-btn :disabled="!permitirAdicionarRecursoCapital"
                    @click="mostrarDialogoAdicionarMateriais('capital')" elevation="2" color="orange" fab outlined
                    small>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao()">
              Fechar
            </v-btn>
            <v-btn v-if="exibirBotaoEnviarSolicitacaoAltAquisicao" :disabled="!validarPercentualMinimoSolicitacao"
              color="blue darken-1" text @click="enviarParaGerenciaRegional()">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Fim Dialog Solicitacao Alteracao de Aquisicao -->

    <v-row justify="center">
      <v-dialog v-model="dialogoRecursoDeCusteio" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTituloRecursoDeCusteio }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formRecursoCusteio" v-model="formularioRecursoCusteioValido" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-text-field label="Cnpj Uex" v-model="solicitacao.cnpjUex" :rules="rules.cnpjUex"
                      :disabled="true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Valor De Repasse" v-model="solicitacao.valorRepasse" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Custeio" v-model="solicitacao.valorCusteio" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-data-table :headers="gridRecurso.headers" :items="solicitacao.recursoCusteio.materiais"
                      no-data-text="Adicione itens">
                      <template v-slot:item.tipoMaterial="{ item }">
                        <span>{{ item.tipoMaterial.materialNome }}</span>
                      </template>

                      <template v-slot:item.material="{ item }">
                        <span>{{ item.nome }}</span>
                      </template>

                      <template v-slot:item.quantidade="{ item }">
                        <span>{{ item.quantidade }}</span>
                      </template>

                      <template v-slot:item.valorUnitario="{ item }">
                        <span>{{ item.valorUnitario | toCurrency }}</span>
                      </template>

                      <template v-slot:item.valorTotal="{ item }">
                        <span>{{ item.valorTotal | toCurrency }}</span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <div>
                          <v-icon color="green" class="mr-3" @click="editarRecursoItem(item, 'custeio')">
                            mdi-pencil
                          </v-icon>
                          <v-icon color="red" @click="deletarRecursoDeCusteio(item)">
                            mdi-delete
                          </v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="text-right pa-0">
                    <v-btn :disabled="!permitirAdicionarRecursoCusteio"
                      @click="mostrarDialogoAdicionarMateriais('custeio')" elevation="2" fab outlined small>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Total Aplicado em Custeio"
                      :value="valorTotalAplicadoEmCusteio"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Disponível em Custeio"
                      :value="totalDisponivelEmCusteioAprovado"></v-currency-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoRecursoDeCusteio()">
              Voltar
            </v-btn>
            <v-btn color="blue darken-1" text @click="salvarRecursoDeCusteio()">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogoRecursoDeCapital" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTituloRecursoCapital }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formCapitalResource" v-model="formularioRecursoCapitalValido" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-text-field label="Cnpj Uex" v-model="solicitacao.cnpjUex" :rules="rules.cnpjUex"
                      :disabled="true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Valor De Repasse" v-model="solicitacao.valorRepasse" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Capital" v-model="solicitacao.valorCapital" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-data-table :headers="gridRecurso.headers" :items="solicitacao.recursoCapital.materiais"
                      no-data-text="Adicione itens">
                      <template v-slot:item.materialNome="{ item }">
                        <span>{{ item.materialNome }}</span>
                      </template>

                      <template v-slot:item.itemNome="{ item }">
                        <span>{{ item.itemNome }}</span>
                      </template>

                      <template v-slot:item.quantidade="{ item }">
                        <span>{{ item.quantidade }}</span>
                      </template>

                      <template v-slot:item.valorUnitario="{ item }">
                        <span>{{ item.valorUnitario | toCurrency }}</span>
                      </template>

                      <template v-slot:item.valorTotal="{ item }">
                        <span>{{ item.valorTotal | toCurrency }}</span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <div>
                          <v-icon color="orange" class="mr-3" @click="editarRecursoItem(item, 'capital')">
                            mdi-pencil
                          </v-icon>
                          <v-icon color="red" @click="deletarRecursoCapital(item)">
                            mdi-delete
                          </v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="text-right pa-0">
                    <v-btn :disabled="!permitirAdicionarRecursoCapital"
                      @click="mostrarDialogoAdicionarMateriais('capital')" elevation="2" fab outlined small>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Total Aplicado em Capital"
                      v-model="valorTotalAplicadoEmCapital"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Disponível em Capital"
                      :value="totalDisponivelEmCapital"></v-currency-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoRecursoDeCapital()">
              Voltar
            </v-btn>
            <v-btn color="blue darken-1" text @click="salvarRecursoDeCapital()">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogoAdicionarMateriais" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formularioTituloMaterial }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formRecurso" v-model="formularioRecursoValido" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select v-model="tipoGrupoMaterial" :items="listaDeMateriaisFiltrada" @change="loadMaterialsItems"
                      return-object item-text="nome" item-value="id" label="Tipo de Material" single-line
                      :rules="rules.select"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <v-select v-if="!habilitarInputMaterialTexto" v-model="tipoMaterialItem"
                      :items="listaDeItensDeMateriais" no-data-text="Selecione primeiro o material" label="Item"
                      item-text="nome" item-value="id" return-object single-line :rules="rules.selectMaterialItem">
                    </v-select>
                    <v-text-field v-if="habilitarInputMaterialTexto" v-model="materialItem.itemNome"
                      label="Digite o nome do material" :rules="rules.nomeMaterial" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-text-field label="Quantidade" v-model="materialItem.quantidade" required
                      @input="calcularValorTotalDaQuantidade" :rules="rules.quantidadeRecurso"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Valor Unitário" @input="calcularValorTotalDaQuantidade"
                      v-model="materialItem.valorUnitario" :rules="rules.valorUnitarioRecurso" required>
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Valor Total" v-model="materialItem.valorTotal" required :disabled="true"
                      :rules="rules.valorTotalRecurso"></v-currency-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoAdicionarMateriais()">
              Cancelar
            </v-btn>
            <v-btn :disabled="!formularioRecursoValido" color="blue darken-1" text @click="manipularMateriais()">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogoNovaSolicitacao" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Nova Aquisição</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formSolicitacao" v-model="formularioSolicitacaoValido" autocomplete="off">
                <v-row>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-text-field label="Código Mec" hint="Digite o código mec para consultar"
                      v-model="solicitacao.codigoMec" :rules="rules.codigoMec" @input="validarTamanhoCodigoMec($event)"
                      @keypress="onlyNumbers($event)">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-text-field :disabled="true" label="Cnpj Uex" v-model="solicitacao.cnpjUex"
                      @keypress="onlyNumbers($event)">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4"
                    class="d-flex flex-column justify-center sm-6 mb-6 pb-0 font-weight-bold">
                    <label>Status</label>
                    <span> {{ solicitacao.status }} </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Valor De Repasse" v-model="solicitacao.valorRepasse" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Custeio" v-model="solicitacao.valorCusteio" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="pb-0">
                    <v-currency-field label="Capital" v-model="solicitacao.valorCapital" :disabled="true">
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Total Aplicado em Custeio"
                      v-model="valorTotalAplicadoEmCusteio"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Disponível em Custeio"
                      :value="totalDisponivelEmCusteioAprovado"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Total Aplicado em Capital"
                      v-model="valorTotalAplicadoEmCapital"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <v-currency-field :disabled="true" label="Valor Disponível em Capital"
                      :value="totalDisponivelEmCapitalAprovado"></v-currency-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-center">
                    <v-btn :disabled="!permitirAdicionarRecursoCusteio" class="mr-3" color="success"
                      @click="dialogoRecursoDeCusteio = true">{{ msgBotaoRecursoCusteio }}</v-btn>
                    <v-btn :disabled="!permitirAdicionarRecursoCapital" color="warning"
                      @click="dialogoRecursoDeCapital = true">{{ msgBotaoRecursoCapital }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="fecharDialogoNovaSolicitacao()">
              Fechar
            </v-btn>
            <v-btn :disabled="!verificarSePodeSalvarSolicitacao" color="blue darken-1" text
              @click="salvarSolicitacao()">
              Salvar
            </v-btn>
            <v-btn :disabled="!validarPercentualMinimoSolicitacao" color="blue darken-1" text
              @click="enviarParaGerenciaRegional()">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div v-show="false">
      <comprovante ref="comprovante" :tituloDocumento="tituloPdf" :solicitacao="solicitacao"
        :valorTotalEmCapital="valorTotalAplicadoEmCapital" :valorTotalEmCusteio="valorTotalAplicadoEmCusteio">
      </comprovante>
    </div>

  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Validations from "services/validations/validations.js";
import notificationMixin from "mixins/notification.mixin";
import pageLoadingMixin from "mixins/page-loading.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";
import Comprovante from "./solicitacao-pdf.vue";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

//const msg_menorde_50porcento = "A soma da solicitação tem um valor menor que 50% .do valor total de repasse.";
const msg_excluir_solicitacao =
  "Tem certeza que deseja excluir essa solicitação?";

export default {
  name: "ManagePaf",
  mixins: [pageLoadingMixin, notificationMixin],
  components: { Comprovante },
  data: () => ({
    validarEnvioParaGerenciaRegional: false,
    solicitacaoEstaEditando: false,
    formularioRecursoCusteioValido: false,
    formularioRecursoValido: false,
    formularioSolicitacaoValido: false,
    formularioRecursoCapitalValido: false,
    habilitarInputMaterialTexto: false,
    dialogoAlteracaoAquisicao: false,
    dialogoVisualizarSolicitacao: false,
    dialogoNovaSolicitacao: false,
    dialogoRecursoDeCusteio: false,
    dialogoRecursoDeCapital: false,
    dialogoAdicionarMateriais: false,
    exibirBotaoEnviarSolicitacao: false,
    exibirBotaoEnviarSolicitacaoAltAquisicao: false,
    tipoDialogoAdicionarMateriais: undefined,
    indiceMaterialEmEdicao: -1,
    indiceMaterialSolAltAquisicaoEmEdicao: -1,
    indiceRecursoDeCusteioEmEdicao: -1,
    indiceRecursoDeCapitalEmEdicao: -1,
    indiceSolicitacao: -1,
    mobileFilter: null,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Paf", to: "/admin/paf" },
    ],
    listaDeMateriaisFiltrada: [],
    listaDeGruposMateriais: [],
    listaDeItensDeMateriais: [],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        cnpjUex: "",
      },
      headers: [
        { text: "Id", value: "solicitacaoId", align: " d-none" },
        { text: "Código Mec", value: "codigoMec" },
        { text: "Cnpj Uex", value: "cnpjUex" },
        { text: "Status", value: "status" },
        { text: "Data de envio", value: "dataEnvio" },
        { text: "Valor Repasse", value: "valorRepasse" },
        { text: "Custeio", value: "valorCusteio" },
        { text: "Capital", value: "valorCapital" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    gridRecurso: {
      headers: [
        { text: "Tipo de Material", value: "materialNome" },
        { text: "Item", value: "itemNome" },
        { text: "Quantidade", value: "quantidade" },
        { text: "Valor Unitário", value: "valorUnitario" },
        { text: "Valor Total", value: "valorTotal" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
    },
    gridDetalharSolicitacao: {
      headers: [
        { text: "Tipo de Material", value: "materialNome" },
        { text: "Item", value: "itemNome" },
        { text: "Quantidade", value: "quantidade" },
        { text: "Valor Unitário", value: "valorUnitario" },
        { text: "Valor Total", value: "valorTotal" },
        { text: "Status", value: "status" },
      ],
    },
    gridSolicitacaoAlterarAquisicao: {
      headers: [
        { text: "Tipo de Material", value: "materialNome" },
        { text: "Item", value: "itemNome" },
        { text: "Quantidade", value: "quantidade" },
        { text: "Valor Unitário", value: "valorUnitario" },
        { text: "Valor Total", value: "valorTotal" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
        { text: "Parecer", value: "parecer" },
      ],
    },
    solicitacaoEstadoInicial: {
      id: undefined,
      codigoMec: "",
      ano: "",
      cnpjUex: "",
      valorRepasse: 0,
      valorCusteio: 0,
      valorCapital: 0,
      saldoCusteio: 0,
      saldoCapital: 0,
      status: "",
      dataEnvio: "",
      statusId: 0,
      recursoCusteio: {
        materiais: [],
      },
      recursoCapital: {
        materiais: [],
      },
      validaParaEnviar: false,
    },
    solicitacao: {
      id: undefined,
      codigoMec: 0,
      ano: "",
      cnpjUex: "",
      valorRepasse: 0,
      valorCusteio: 0,
      valorCapital: 0,
      saldoCusteio: 0,
      saldoCapital: 0,
      status: "",
      dataEnvio: "",
      statusId: 0,
      recursoCusteio: {
        materiais: [],
      },
      recursoCapital: {
        materiais: [],
      },
      validaParaEnviar: false,
    },
    tipoGrupoMaterial: {
      id: 0,
      nome: "",
    },
    tipoMaterialItem: {
      id: 0,
      nome: "",
    },
    tipoGrupoMaterialInicial: {
      id: 0,
      nome: "",
    },
    tipoMaterialItemInicial: {
      id: 0,
      nome: "",
    },
    materialItemEstadoInicial: {
      itemId: 0,
      recursoId: 0,
      materialId: 0,
      recursoItemId: 0,
      codTipo: 0,
      itemNome: "",
      materialNome: "",
      quantidade: 1,
      valorUnitario: 0,
      valorTotal: 0,
    },
    materialItem: {
      itemId: 0,
      recursoId: 0,
      materialId: 0,
      recursoItemId: 0,
      codTipo: 0,
      itemNome: "",
      materialNome: "",
      quantidade: 1,
      valorUnitario: 0,
      valorTotal: 0,
    },
    valorTotCus : 0,
    tituloPdf: "Dados da solicitação",
    recursoEmEdicao: {},
    solicitacaoParaComprovante: {},
  }),
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  mounted() {
    this.getListaDeMateriais();
    this.getListaDeSolicitacoesDoUsuario();
  },
  computed: {
    ...postLoginGetters([USER]),
    validarPercentualMinimoSolicitacao() {
      const metadeValorRepasse = parseFloat(this.solicitacao.valorRepasse / 2);
      const somaValoresAplicados =
        this.valorTotalAplicadoEmCusteio + this.valorTotalAplicadoEmCapital;
      if (somaValoresAplicados === 0 && metadeValorRepasse === 0) return false;
      return somaValoresAplicados >= metadeValorRepasse;
    },
    valorTotalAplicadoEmCusteio() {
      return this.solicitacao.recursoCusteio.materiais.reduce(
        (acc, value) => acc + value.valorTotal,
        0);
    },
    valorTotalAplicadoEmCapital() {
      return this.solicitacao.recursoCapital.materiais.reduce(
        (acc, value) => acc + value.valorTotal,
        0
      );
    },
    verificarSePodeSalvarSolicitacao() {
      return (
        this.solicitacao.codigoMec.toString().length >= 8 &&
        this.solicitacao.cnpjUex
      );
    },
    obterSolicitacaoParaEnvio() {
      return {
        id: this.solicitacao.id,
        ano: this.solicitacao.ano,
        codigoMec: this.solicitacao.codigoMec,
        valorRepasse: this.solicitacao.valorRepasse,
        valorCusteio: this.solicitacao.valorCusteio,
        valorCapital: this.solicitacao.valorCapital,
        saldoCusteio: this.totalDisponivelEmCusteioAprovado,
        saldoCapital: this.totalDisponivelEmCapitalAprovado,
        nomeDeUsuario: this.user.username,
      };
    },
    totalDisponivelEmCusteio() {
      var result = this.solicitacao.saldoCusteio - this.valorTotalAplicadoEmCusteio;
      return result <= 0 ? 0 : result;
    },
    totalDisponivelEmCusteioAprovado() {
      if (this.solicitacao.status.toLowerCase() === "aprovado") {
        var result = this.solicitacao.valorCusteio - this.valorTotalAplicadoEmCusteio;
        return result <= 0 ? 0 : result;
      } else return this.totalDisponivelEmCusteio;
    },
    totalDisponivelEmCapital() {
      var result = this.solicitacao.saldoCapital - this.valorTotalAplicadoEmCapital;
      return result <= 0 ? 0 : result;
    },
    totalDisponivelEmCapitalAprovado() {
      if (this.solicitacao.status.toLowerCase() === "aprovado") {
        var result = this.solicitacao.valorCapital - this.valorTotalAplicadoEmCapital;
        return result <= 0 ? 0 : result;
      } else return this.totalDisponivelEmCapital;
    },
    filteredItems() {
      return this.grid.data.filter((i) => {
        return i.cnpjUex.includes(this.grid.filters.cnpjUex);
      });
    },
    msgBotaoRecursoCusteio() {
      return this.solicitacaoEstaEditando
        ? "Editar Aquisição de Custeio"
        : "Adicionar Aquisição de Custeio";
    },
    msgBotaoRecursoCapital() {
      return this.solicitacaoEstaEditando
        ? "Editar Aquisição de Capital"
        : "Adicionar Aquisição de Capital";
    },
    formularioTituloMaterial() {
      return this.indiceMaterialEmEdicao === -1
        ? "Adicionar Material"
        : "Editar Material";
    },
    formTituloRecursoDeCusteio() {
      return this.indiceRecursoDeCusteioEmEdicao === -1
        ? "Cadastrar Aquisição de Custeio"
        : "Editar Aquisição de Custeio";
    },
    formTituloRecursoCapital() {
      return this.indiceRecursoDeCapitalEmEdicao === -1
        ? "Cadastrar Aquisição de Capital"
        : "Editar Aquisição de Capital";
    },
    permitirAdicionarRecursoCusteio() {
      return this.totalDisponivelEmCusteioAprovado > 0;
    },
    permitirAdicionarRecursoCapital() {
      return this.totalDisponivelEmCapitalAprovado > 0;
    },
    rules() {
      return {
        cnpjUex: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 14),
          (value) => Validations.max(value, 14),
        ],
        codigoMec: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 8),
        ],
        quantidadeRecurso: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 1),
        ],
        valorUnitarioRecurso: [
          (value) => Validations.required(value),
          (value) => value !== "0,00" || "Esse campo é obrigatório",
        ],
        valorTotalRecurso: [
          (value) => Validations.required(value),
          (value) => value !== "0,00" || "Esse campo não pode ter valor zero",
        ],
        nomeMaterial: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 3),
        ],
        select: [(value) => !!value || "Esse campo é obrigatório"],
        selectMaterialItem: [(value) => !!value || "Esse campo é obrigatório"],
      };
    },
  },
  methods: {
    imprimirPdfSolicitacao(titulo) {
      this.tituloPdf = titulo;
      const conteudo = this.$refs.comprovante.getHtml();
      let win = window.open();
      win.document.write(conteudo);
      win.print();
      win.close();
    },
    async preSolicitacaoDownload(item) {
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.verificarStatusDaSolicitacao();
    },
    async downloadComprovante(item) {
      await this.preSolicitacaoDownload(item);
      const conteudo = this.$refs.comprovante.getHtml();

      this.showPageLoading();
      this.$api()
        .post(configurations.api.admin.servicos.postHtmlParaPdfUri, {
          html: conteudo,
        })
        .then(({ data }) => {
          const linkSource = `data:application/pdf;base64,${data.pdf}`;
          const downloadLink = document.createElement("a");
          const fileName = `solicitacao-comprovante.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        })
        .then(() => {
          this.hidePageLoading();
        });
    },
    verificarStatusDaSolicitacao() {
      this.exibirBotaoEnviarSolicitacao =
        this.solicitacao.status.toLowerCase() === "salvo";
    },
    habilitarBotaoSolicitacaoAquisicao() {
      this.exibirBotaoEnviarSolicitacaoAltAquisicao =
        this.solicitacao.status.toLowerCase() === "aprovado";
    },
    verificarAcoesDaSolicitacao(item) {
      return item.status.toLowerCase() !== "salvo";
    },
    verificarAcoesDaSolicitacaoAlterAquisicao(item) {
      return item.status.toLowerCase() === "aprovado";
    },
    deletarSolicitacao(item) {
      item.nomeDeUsuario = this.user.username;
      this.indiceSolicitacao = this.grid.data.indexOf(item);
      var result = confirm(msg_excluir_solicitacao);
      if (result) {
        this.$api()
          .post(configurations.api.admin.paf.getDeletarSolicitacaoUri, item)
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.grid.data.splice(this.indiceSolicitacao, 1);
              this.notifySuccess("Solicitação deletada com sucesso.");
            }
          })
          .catch(({ response }) => {
            this.verificarSeAcessoAutorizado(response);
            this.exibirMensagemDeRetorno(
              response,
              "Não foi possível deletar a solicitação atual, verificar as regras."
            );
          });
      }
    },
    editarRecursoItem(item, tipo) {
      this.indiceMaterialEmEdicao = 1;
      this.converterRecursoItemEmMaterial(item, tipo);
      this.materialItem = Object.assign({}, item);
      this.mostrarDialogoAdicionarMateriais(tipo);
    },
    editarRecusoItemSolicitacaoAlteracaoRequisicao(item, tipo) {
      this.indiceMaterialEmEdicao = 1;
      this.recursoEmEdicao.tipo = tipo;
      this.recursoEmEdicao.item = this.obterMaterilEmEdicaoDaListaDeRecurso(item, tipo);
      this.converterRecursoItemEmMaterial(item, tipo);
      this.materialItem = Object.assign({}, item);
      this.mostrarDialogoAdicionarMateriais(tipo);
    },
    obterIndicePeloTipoDeRecurso(item, tipo) {
      if (tipo.toLowerCase() === "custeio")
        this.indiceMaterialEmEdicao = this.solicitacao.recursoCusteio.materiais.indexOf(
          item
        );
      else
        this.indiceMaterialEmEdicao = this.solicitacao.recursoCapital.materiais.indexOf(
          item
        );
    },
    obterMaterilEmEdicaoDaListaDeRecurso(item, tipo) {
      if (tipo.toLowerCase() === "custeio") {
        const indexCusteio = this.solicitacao.recursoCusteio.materiais.indexOf(
          item
        );
        this.indiceMaterialSolAltAquisicaoEmEdicao = indexCusteio;
        return this.solicitacao.recursoCusteio.materiais[indexCusteio];
      }
      else {
        const indexCapital = this.solicitacao.recursoCapital.materiais.indexOf(
          item
        );
        this.indiceMaterialSolAltAquisicaoEmEdicao = indexCapital;
        return this.solicitacao.recursoCapital.materiais[indexCapital];
      }
    },
    converterRecursoItemEmMaterial(item, tipo) {
      const codTipo = tipo.toLowerCase() === "custeio" ? 1 : 2;
      this.tipoGrupoMaterial.id = item.materialId;
      this.tipoGrupoMaterial.nome = item.materialNome;
      this.tipoMaterialItem.id = item.itemId;
      this.tipoMaterialItem.nome = item.itemNome;
      const listaDeMateriais = this.listaDeGruposMateriais.filter(
        (m) => m.id == codTipo
      )[0].materiais;
      this.listaDeItensDeMateriais = listaDeMateriais.filter(
        (mt) => mt.id == item.materialId
      )[0].itens;
    },
    editarRecursoDeCusteio(item) {
      this.indiceRecursoDeCusteioEmEdicao = this.grid.data.indexOf(item);
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.dialogoRecursoDeCusteio = true;
    },
    editarRecursoDeCapital(item) {
      this.indiceRecursoDeCapitalEmEdicao = this.grid.data.indexOf(item);
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.dialogoRecursoDeCapital = true;
    },
    visualizarSolicitacaoAlteracaoAquisicao(item) {
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.habilitarBotaoSolicitacaoAquisicao();
      this.dialogoAlteracaoAquisicao = true;
    },
    visualizarSolicitacao(item) {
      this.solicitacao = this.converterEmSolicitacao(this.solicitacao, item);
      this.verificarStatusDaSolicitacao();
      this.dialogoVisualizarSolicitacao = true;
    },
    converterEmSolicitacao(solicitacao, item) {
      solicitacao.cnpjUex = item.cnpjUex;
      solicitacao.ano = item.ano;
      solicitacao.codigoMec = item.codigoMec;
      solicitacao.id = item.id;
      solicitacao.saldoCapital = item.saldoCapital;
      solicitacao.saldoCusteio = item.saldoCusteio;
      solicitacao.valorRepasse = item.valorRepasse;
      solicitacao.valorCusteio = item.valorCusteio;
      solicitacao.valorCapital = item.valorCapital;
      solicitacao.status = item.status;
      solicitacao.statusId = item.statusId;
      solicitacao.dataEnvio = item.dataEnvio;
      if (this.solicitacao.itensDeRecurso)
        this.atribuirListaDeRecursoItens(solicitacao.itensDeRecurso);
      else this.atribuirListaDeRecursoItens(item.itensDeRecurso);
      return solicitacao;
    },
    atribuirMaterialItem() {
      this.materialItem.materialId = this.tipoGrupoMaterial.id;
      this.materialItem.materialNome = this.tipoGrupoMaterial.nome;
      this.materialItem.itemId = this.tipoMaterialItem.id;
      this.materialItem.itemNome = this.tipoMaterialItem.nome;
    },
    salvarSolicitacao() {
      this.getListaDeSolicitacoesDoUsuario();
      this.dialogoNovaSolicitacao = false;
    },
    enviarParaGerenciaRegional() {
      this.showPageLoading();
      this.$api()
        .post(
          configurations.api.admin.paf.getEnviarSolicitacaoParaGreUri,
          this.obterSolicitacaoParaEnvio
        )
        .then(({ data }) => {
          if (data) {
            this.notifySuccess("Solicitação enviada com sucesso!");
          }
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível enviar a solicitação para gerência regional"
          );
        })
        .then(() => {
          this.hidePageLoading();
          this.fecharDialogoVisualizarSolicitacao();
          this.dialogoNovaSolicitacao = false;
          this.fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao();
          this.getListaDeSolicitacoesDoUsuario();
        });
    },
    mostrarDialogoAdicionarMateriais(value) {
      this.tipoDialogoAdicionarMateriais = value;
      this.listaDeMateriaisFiltrada = this.listaDeGruposMateriais.filter(
        (mt) => mt.nome.toLowerCase() === value
      )[0].materiais;
      this.dialogoAdicionarMateriais = true;
    },
    abrirDialogoNovaSolicitacao() {
      this.solicitacao = Object.assign({}, this.solicitacaoEstadoInicial);
      this.solicitacao.recursoCusteio.materiais = [];
      this.solicitacao.recursoCapital.materiais = [];
      this.indiceRecursoDeCusteioEmEdicao = -1;
      this.indiceRecursoDeCapitalEmEdicao = -1;
      this.dialogoNovaSolicitacao = true;
    },
    fecharDialogoNovaSolicitacao() {
      this.getListaDeSolicitacoesDoUsuario();
      if (this.solicitacao.codigoMec && this.solicitacao.cnpjUex) {
        this.dialogoNovaSolicitacao = !confirm(
          "Tem certeza que deseja fechar? todo progresso não salvo será perdido."
        );
      } else this.dialogoNovaSolicitacao = false;
    },
    validarTamanhoCodigoMec(codigoMec) {
      if (codigoMec.length >= 8) {
        this.getSolicitacaoAtual();
      }
    },
    onlyNumbers(event) {
      if (
        event.charCode === 0 ||
        /\d/.test(String.fromCharCode(event.charCode))
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    calcularValorTotalDaQuantidade() {
      this.materialItem.valorTotal =
        this.materialItem.quantidade * this.materialItem.valorUnitario;
    },
    deletarRecursoDeCusteio(item) {
      const index = this.solicitacao.recursoCusteio.materiais.indexOf(item);
      this.deletarRecurso(item, "custeio", index);
    },
    deletarRecursoCapital(item) {
      const index = this.solicitacao.recursoCapital.materiais.indexOf(item);
      this.deletarRecurso(item, "capital", index);
    },
    removerRecursoDaLista(tipo, index) {
      if (tipo === "capital") {
        this.solicitacao.recursoCapital.materiais.splice(index, 1);
      } else {
        this.solicitacao.recursoCusteio.materiais.splice(index, 1);
      }
      this.notifySuccess("Item deletado com sucesso!");
    },
    deletarRecurso(item, tipo, index) {
      this.showPageLoading();
      item.tipoSaldo = tipo;
      item.nomeDeUsuario = this.user.username;
      item.solicitacaoId = this.solicitacao.id;
      this.$api()
        .post(configurations.api.admin.paf.getDeletarRecursoItemUri, item)
        .then(({ data }) => {
          if (data && data.sucesso) this.removerRecursoDaLista(tipo, index);
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível deletar o item"
          );
        })
        .then(() => {
          this.hidePageLoading();
          this.fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao();
          this.getListaDeSolicitacoesDoUsuario();
        });
    },
    getListaDeSolicitacoesDoUsuario() {
      this.grid.loading = true;
      this.showPageLoading();
      this.$api()
        .get(configurations.api.admin.paf.getObterListaDeSolicitacaoUri, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          if (data && data.dados) {
            this.grid.data = data.dados;
          }
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foram encontradas solicitações para esse usuário."
          );
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    getSolicitacaoAtual() {
      this.showPageLoading();
      this.$api()
        .get(configurations.api.admin.paf.getSolicitacaoAtualUri, {
          params: {
            codigoMec: this.solicitacao.codigoMec,
            nomeDeUsuario: this.user.username,
            cnpjUex: this.solicitacao.cnpjUex,
          },
        })
        .then(({ data }) => {
          if (data && data.dados) {
            this.solicitacao.id = data.dados.id;
            this.solicitacao.cnpjUex = data.dados.cnpjUex;
            this.solicitacao.status = data.dados.status;
            this.solicitacao.statusId = data.dados.statusId;
            this.solicitacao.ano = data.dados.ano;
            this.solicitacao.valorRepasse = data.dados.valorRepasse;
            this.solicitacao.valorCusteio = data.dados.valorCusteio;
            this.solicitacao.valorCapital = data.dados.valorCapital;
            this.solicitacao.saldoCusteio = data.dados.saldoCusteio;
            this.solicitacao.saldoCapital = data.dados.saldoCapital;
            this.atribuirListaDeRecursoItens(data.dados.itensDeRecurso);
            this.getListaDeSolicitacoesDoUsuario();
          } else {
            this.notifyWarning(
              "Não foram encontradas informações de repasse para o cnpj informado."
            );
          }
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível abrir a solicitação para o código mec informado"
          );
        })
        .then(() => {
          this.hidePageLoading();
          this.grid.loading = false;
        });
    },
    getListaDeMateriais() {
      this.$api()
        .get(configurations.api.admin.paf.getListaDeMateriaisUri, {
          params: {
            nomeDeUsuario: this.user.username,
          },
        })
        .then(({ data }) => {
          if (data && data.dados) {
            this.listaDeGruposMateriais = data.dados;
          } else {
            this.notifyWarning(
              "Não foram encontradas informações de materiais."
            );
          }
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possivel carregar a lista de materiais."
          );
        });
    },
    loadMaterialsItems() {
      this.habilitarInputMaterialTexto = this.tipoGrupoMaterial.nome
        .toLowerCase()
        .includes("outro");
      this.listaDeItensDeMateriais = this.tipoGrupoMaterial.itens;
    },
    manipularMateriais() {
      this.$refs.formRecurso.validate();
      this.atribuirMaterialItem();
      const valorTotal = this.materialItem.valorTotal; // this.obterTotalComValorAplicado();

      let valorDisponivel = this.obterValorDisponivel();

      if(this.indiceMaterialEmEdicao > -1) {
        valorDisponivel = this.obterValorDisponivelEmItemEditado;
      }

      if (this.indiceMaterialSolAltAquisicaoEmEdicao > -1) {
        this.setarNovoValorDeSaldoDisponivel(this.recursoEmEdicao.item.valorTotal, valorTotal);
        valorDisponivel = this.obterValorDisponivelEmItemEditado;
      }

      if (
        !this.verificarSeValorUltrapassaTotalDisponivel(
          valorTotal,
          valorDisponivel
        )
      ) {
        this.enviarMaterialItemParaApi();
      }
      this.fecharDialogoAdicionarMateriais();
    },
    setarNovoValorDeSaldoDisponivel(valor, valorTotal) {
      this.materialItem.tipoSaldo = this.recursoEmEdicao.tipo;
      if (this.recursoEmEdicao.tipo === "custeio") {
        this.solicitacao.saldoCusteio += valor;
        this.solicitacao.recursoCusteio.materiais[this.indiceMaterialSolAltAquisicaoEmEdicao].valorTotal = valorTotal;
        this.materialItem.saldoRestituir = this.solicitacao.valorCusteio - this.valorTotalAplicadoEmCusteio;
      } else {
        this.solicitacao.saldoCapital += valor;
        this.solicitacao.recursoCapital.materiais[this.indiceMaterialSolAltAquisicaoEmEdicao].valorTotal = valorTotal;
        this.materialItem.saldoRestituir = this.solicitacao.valorCapital - this.valorTotalAplicadoEmCapital;
      }
    },
    obterValorDisponivel() {
      if (this.tipoDialogoAdicionarMateriais === "custeio")
        return this.totalDisponivelEmCusteioAprovado;
      else return this.totalDisponivelEmCapitalAprovado;
    },
    obterValorDisponivelEmItemEditado() {
      if (this.tipoDialogoAdicionarMateriais === "custeio")
        return this.solicitacao.valorCusteio;
      else return this.solicitacao.valorCapital;
    },
    obterTotalComValorAplicado() {
      if (this.tipoDialogoAdicionarMateriais === "custeio") {
        return parseFloat(
          this.materialItem.valorTotal + this.valorTotalAplicadoEmCusteio
        );
      } else {
        return parseFloat(
          this.materialItem.valorTotal + this.valorTotalAplicadoEmCapital
        );
      }
    },
    verificarSeValorUltrapassaTotalDisponivel(total, disponivel) {
      if (total > disponivel) {
        this.notifyWarning(
          `O total dos itens ultrapassa o valor disponível em ${this.tipoDialogoAdicionarMateriais}`
        );
      }
      return total > disponivel;
    },
    enviarMaterialItemParaApi() {
      if (this.indiceMaterialEmEdicao > -1) {
        this.salvarEdicaoMaterialItem();
      } else {
        this.salvarMaterialItem();
      }
    },
    salvarMaterialItem() {
      this.showPageLoading();
      this.materialItem.nomeDeUsuario = this.user.username;
      this.materialItem.solicitacaoId = this.solicitacao.id;
      this.$api()
        .post(
          configurations.api.admin.paf.getSalvarRecursoItemUri,
          this.materialItem
        )
        .then(({ data }) => {
          this.processarListaDeItensDoRecurso(data);
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível adicionar o item"
          );
        })
        .then(() => {
          this.limparArraysDeMateriais();
          this.hidePageLoading();
        });
    },
    salvarEdicaoMaterialItem() {
      this.showPageLoading();
      this.materialItem.nomeDeUsuario = this.user.username;
      this.materialItem.solicitacaoId = this.solicitacao.id;
      this.$api()
        .post(
          configurations.api.admin.paf.getEditarRecursoItemUri,
          this.materialItem
        )
        .then(({ data }) => {
          this.processarListaDeItensDoRecurso(data);
        })
        .catch(({ response }) => {
          this.verificarSeAcessoAutorizado(response);
          this.exibirMensagemDeRetorno(
            response,
            "Não foi possível editar o item"
          );
        })
        .then(() => {
          this.hidePageLoading();
          this.limparArraysDeMateriais();
          this.indiceMaterialEmEdicao = -1;
          this.recursoEmEdicao = null;
          this.indiceMaterialSolAltAquisicaoEmEdicao = -1;
          this.recursoEmEdicao = {};
          this.fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao();
          this.getListaDeSolicitacoesDoUsuario();
        });
    },
    limparArraysDeMateriais() {
      this.tipoGrupoMaterial = Object.assign({}, this.tipoGrupoMaterialInicial);
      this.tipoMaterialItem = Object.assign({}, this.tipoMaterialItemInicial);
      this.listaDeItensDeMateriais = [];
      this.listaDeMateriaisFiltrada = [];
    },
    exibirMensagemDeRetorno(response, msg) {
      if (response && response.data.mensagem) {
        this.notifyWarning(response.data.mensagem);
      } else {
        this.notifyWarning(msg);
      }
    },
    verificarSeAcessoAutorizado(response) {
      if (response && response.status == 401)
        this.$router.push({ path: "/login" });
    },
    processarListaDeItensDoRecurso(data) {
      if (data && data.itensDeRecurso) {
        this.solicitacao.itensDeRecurso = data.itensDeRecurso;
        this.atribuirListaDeRecursoItens(data.itensDeRecurso);
        this.notifySuccess("Item editado com sucesso!");
      }
    },
    salvarRecursoDeCusteio() {
      this.dialogoRecursoDeCusteio = false;
      this.forcaRecarregamento();
    },
    salvarRecursoDeCapital() {
      this.dialogoRecursoDeCapital = false;
      this.forcaRecarregamento();
    },
    fecharDialogoRecursoDeCusteio() {
      this.dialogoRecursoDeCusteio = false;
      this.forcaRecarregamento();
    },
    fecharDialogoRecursoDeCapital() {
      this.dialogoRecursoDeCapital = false;
      this.forcaRecarregamento();
    },
    fecharDialogoVisualizarSolicitacao() {
      this.dialogoVisualizarSolicitacao = false;
    },
    fecharDialogoVisualizarSolicitacaoAlteracaoAquisicao() {
      this.dialogoAlteracaoAquisicao = false;
    },
    fecharDialogoAdicionarMateriais() {
      this.materialItem = Object.assign({}, this.materialItemEstadoInicial);
      this.limparArraysDeMateriais();
      this.dialogoAdicionarMateriais = false;
      this.listaDeItensDeMateriais = [];
      this.listaDeMateriaisFiltrada = [];
      this.indiceMaterialEmEdicao = -1;
    },
    filter() {
      if (this.grid.filters.filters || this.grid.filters.cnpjUex) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    atribuirListaDeRecursoItens(lista) {
      this.solicitacao.recursoCusteio.materiais = lista.filter(
        (item) => item.codTipo === 1
      );
      this.solicitacao.recursoCapital.materiais = lista.filter(
        (item) => item.codTipo === 2
      );
    },
    definirIconStatusItem(item) {
      if (item.status && item.status.toLowerCase() === "aprovado")
        return "mdi-checkbox-marked";
      if (item.status && item.status.toLowerCase() === "negado")
        return "mdi-close-box-outline";
      return "";
    },
    definirCorIconStatusItem(item) {
      if (item.status && item.status.toLowerCase() === "aprovado") return "green";
      if (item.status && item.status.toLowerCase() === "negado") return "red";
      return "";
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/paf").length === 0) {
        this.$router.push({ path: "/login" });
      }
    },
    forcaRecarregamento() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}

.hidden {
  visibility: hidden;
  display: none;
}

.text-green input {
  color: green !important;
}

.hide-table {
  display: none;
}
</style>