export default function (value, message = `Campo deve conter apenas números`, condition = undefined) {
    if (typeof condition !== 'function') {
      return rule(value, message);
    }
  
  
    if (condition(value)) {
      return rule(value, message);
    } else {
      return message;
    }
  }
  
  function rule(value, message) {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return message;
    }

    if(/^[0-9]+$/.test(value)){
        return true;
    }
    return message;
  }