export default function (value, comparing, message = 'Valor incompatível', condition = undefined) {
  if (typeof condition !== 'function') {
    return rule(value, comparing, message);
  }

  if (condition(value)) {
    return rule(value, comparing, message);
  } else {
    return message;
  }
}

function rule(value, comparing, message) {
  return value === comparing || message;
}