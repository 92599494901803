export default function(value, min = 0, message = `Campo deve ter pelo menos ${min} caracteres`, condition = undefined) {
  if (typeof condition !== 'function') {
    return rule(value, min, message)
  }

  if (condition(value, min)) {
    return rule(value, min, message);
  } else {
    return message;
  }
}

function rule(value, min, message) {
  if (typeof value === 'string' && value.trim().length >= min) {
    return true;
  }

  if (typeof value === 'number' && value >= min) {
    return true;
  }  

  return message;
}