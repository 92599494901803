export default function (value, max = 0, message = `Campo deve ter no máximo ${max} caracteres`, condition = undefined) {
  if (typeof condition !== 'function') {
    return rule(value, max, message);
  }


  if (condition(value, max)) {
    return rule(value, max, message);
  } else {
    return message;
  }
}

function rule(value, max, message) {
  if (typeof value == 'string' && value.trim().length <= max) {
    return true;
  }

  if (typeof value == 'number' && value <= max) {
    return true;
  }

  return message;
}