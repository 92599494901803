import required from './required.validations'
import min from './min.validations'
import max from './max.validations'
import email from './email.validations'
import equals from './equals.validations'
import onlyNumber from './only-numbers.validations'

export default {
  required,
  min,
  max,
  email,
  equals,
  onlyNumber
}