export default function (value, domains = [], message = 'Campo deve conter um email válido', condition = undefined) {
  if (typeof condition !== 'function') {
    return rule(value, domains, message);
  }

  if (condition(value)) {
    return rule(value, domains, message);
  } else {
    return message;
  }
}

// eslint-disable-next-line no-useless-escape
const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function rule(value, domains, message) {
  if (typeof value == 'string' && value.length) {
    let isValid = pattern.test(value);

    if (domains.length) {
      isValid = domains.filter(domain => domain.toLowerCase().trim() === value.toLowerCase().trim().split('@')[1]).length > 0;
    }

    return isValid || message;
  }

  return message;
}